export const TOTAL_STEPS = {
  REGULAR: 5,
  CITY: 6,
  SHORTER_FLOW: 4,
}
export const SOONEST_AVAILABLE_PRACTITIONER_ID = 'SOONEST_AVAILABLE_PRACTITIONER_ID'

export const DATE_FORMATS = {
  DAY_MONTH_YEAR: {
    de: 'dd. MMMM yyyy',
    en: 'dd MMMM yyyy',
  },
  WEEKDAY_DAY_MONTH_YEAR_TIME: {
    de: 'E dd. MMMM yyyy | HH:mm',
    en: 'E. dd MMMM yyyy | HH:mm',
  },
  DAY_MONTH_YEAR_TIME: {
    de: 'dd. MMMM yyyy | HH:mm',
    en: 'dd MMMM yyyy | HH:mm',
  },
  TIME: 'HH:mm',
  APPOINTMENT_CONFIRMATION: {
    de: "EEEE, dd.MM.yyyy 'um' HH:mm",
    en: "EEEE, dd.MM.yyyy 'at' HH:mm a",
  },
  DAY_MONTH: 'd MMMM',
}

export const DEFAULT_PRIVAY_POLICY_PAGE = `${document.location.origin}/datenschutzerklaerung/`
export const GENERAL_TERMS_AND_CONDITIONS_SUBPAGE = '/general-terms-and-conditions/'
export const DEFAULT_GENERAL_TERMS_AND_CONDITIONS_PAGE = `${document.location.origin}${GENERAL_TERMS_AND_CONDITIONS_SUBPAGE}`

export const fiveMinutesInMiliseconds = 5 * 60 * 1000
