export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Preview = 'preview',
  Production = 'production',
}

export interface InitAvaily {
  asModal: boolean
  elementId?: string
  clinicId: string
  successUrl?: string
  lang: string
  loadOnDemand?: boolean
  fallbackIframe?: boolean
  colors?: Record<string, string>
  skipCreatingNewModalContainer?: boolean
  apiBaseUrl?: string
  env?: Environment
  sourceWebsiteType?: SourceWebsiteType
  privacyLink?: string
  generalTermsAndConditionsLink?: string
}

export enum SourceWebsiteType {
  AVAILY_OTHER = 'AVAILY_OTHER',
  AVAILY_P21 = 'AVAILY_P21',
  AVAILY_D21 = 'AVAILY_D21',
  AVAILY_MEDPRESS = 'AVAILY_MEDPRESS',
}

export enum CreationEvent {
  GAP_FILLER = 'GAP_FILLER',
  RECOMMENDATION = 'RECOMMENDATION',
  RECALL = 'RECALL',
  RESCHEDULING = 'RESCHEDULING',
  UPSELL = 'UPSELL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}
