import { AnalyticsAction, AnalyticsScreen } from './Analytics'
import { AppMode } from './Generic'

export enum AvailyEvent {
  INIT = 'INIT',
  HEIGHT_CHANGED = 'HEIGHT_CHANGED',
  BOOKING_CREATED = 'BOOKING_CREATED',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_MODAL = 'OPEN_MODAL',
  GTM_EVENT_EMMITED = 'GTM_EVENT_EMMITED',
  GTM_FORM_EVENT_EMMITED = 'GTM_FORM_EVENT_EMMITED',
  OPEN_SURVEY = 'OPEN_SURVEY',
  READY = 'READY',
}
export interface Event {
  type?: AvailyEvent | string
  target?: string
  data?: Record<string, unknown>
  category?: 'form' | 'availy' | AppMode
  /**
   * Payload is used by Analytics event
   * or when type AvailyEvent.BOOKING_CREATED
   * in this case the payload is the configured
   * success url
   */
  payload?:
    | string
    | number
    | {
        screen?: AnalyticsScreen | string
        action?: AnalyticsAction | string
        label?: string
      }
}
