import qs from 'query-string'

import { Config } from '@customTypes/Config'
import { AppMode } from '@customTypes/Generic'
import getTrackingParams from './utils/getTrackingParams'
import { postAvailyMsg } from './utils/postMessage'
import listenMessages from './utils/listenMessages'

import toJSON from './helpers/toJSON'

import { dispatchTrackingEvent, dispatchAnalyticsEvent } from './services/analytics'
import { InitAvaily, SourceWebsiteType } from './lib/types'
import { getAvailyGTMScriptContent } from './lib'
import { DEFAULT_GENERAL_TERMS_AND_CONDITIONS_PAGE, DEFAULT_PRIVAY_POLICY_PAGE } from './config/constants'

const IFRAME_ELEMENT_ID = 'availy-iframe'

declare global {
  interface Window {
    env: any
  }
}

interface CreateIframeProps extends InitAvaily {
  sourceWebsite: string
  scriptUrl: string
}

type HandleAvailyMessagesProps = {
  asModal?: boolean
  initialOverflowY?: string
  loadOnDemand?: boolean
  /**
   * Set to any as in one case data.payload is a string
   * and in another it is an object containing screen and action
   */
  data: any
}

const CIProvidedPublicPath = 'PUBLIC_PATH_PLACEHOLDER' // we will replace this value in build time based in the current CI stage environment
const availyUrl = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_PUBLIC_PATH : CIProvidedPublicPath

const createHead = () => {
  const head = document.createElement('head')
  const meta = document.createElement('meta')
  meta.setAttribute('charSet', 'utf-8')
  const viewMeta = document.createElement('meta')
  viewMeta.setAttribute('name', 'viewport')
  viewMeta.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no')
  head.appendChild(meta)
  head.appendChild(viewMeta)
  return head
}

const createMainStyles = () => {
  const link = document.createElement('link')

  link.href = `${availyUrl}/static/css/main.styles.css`

  link.rel = 'stylesheet'
  link.type = 'text/css'
  return link
}

const createScript = (scriptUrl: string, callback: () => void) => {
  const script = document.createElement('script')
  script.onload = callback
  script.src = scriptUrl
  return script
}

const createInitScript = (config: Config) => {
  const script = document.createElement('script')
  script.text = `new availy.Availy('root', ${JSON.stringify(config)})`
  return script
}

const createGTMScript = () => {
  const script = document.createElement('script')
  script.text = getAvailyGTMScriptContent()
  return script
}

const createConfigScript = (callback: () => void) => {
  const script = document.createElement('script')
  script.onload = callback
  script.src = `${availyUrl}/config.js`
  return script
}

const createColorsScript = (colors?: string): HTMLScriptElement => {
  const script = document.createElement('script')

  if (!colors) return script

  script.text = `window.colors = ${colors}`
  return script
}

const createDiv = (id: string) => {
  const div = document.createElement('div')
  div.id = id
  return div
}

/**
 * @param originElement the element that will be used as
 * source for the height that will be applied to the iframe
 */
const setIframeHeight = ({ height, originElement }: { height?: number; originElement?: HTMLElement }) => {
  if (!height && !originElement) return

  const iframe = document.getElementById(IFRAME_ELEMENT_ID)

  if (iframe) {
    /**
     * Because of how Availy is currently styled
     * for mobile devices the orgiginElement, usually the body of the iframe
     * does not have a propper heigh. In this case we set the height of the iframe to 100vh
     */
    if (window.innerWidth < 600) {
      iframe.style.height = '100vh'
      return
    }

    /**
     * Before we apply the new height, we first need to reset it,
     * iorder to get the correct current height of the body.
     */
    iframe.style.height = '0px'
    iframe.style.height = `${height || originElement?.scrollHeight || 710}px`
  }
}

const createHtml = (id: string, scriptUrl: string, config: Config) => {
  const html = document.createElement('html')
  const body = document.createElement('body')
  const head = createHead()

  const gtmScript = createGTMScript()
  const colorsScript = createColorsScript(config.colors)
  const configScript = createConfigScript(() => {
    const initScript = createInitScript(config)
    const script = createScript(scriptUrl, () => {
      body.appendChild(initScript)
    })
    body.appendChild(script)
  })

  const div = createDiv(id)

  const mainStyles = createMainStyles()
  head.appendChild(colorsScript)
  head.appendChild(gtmScript)
  body.appendChild(configScript)
  head.appendChild(mainStyles)
  body.appendChild(div)
  html.appendChild(head)
  html.appendChild(body)

  return html
}

const createIframe = ({
  asModal,
  elementId,
  clinicId,
  successUrl,
  lang,
  sourceWebsite,
  scriptUrl,
  fallbackIframe,
  colors = {},
  sourceWebsiteType,
  privacyLink = DEFAULT_PRIVAY_POLICY_PAGE,
  generalTermsAndConditionsLink = DEFAULT_GENERAL_TERMS_AND_CONDITIONS_PAGE,
}: CreateIframeProps) => {
  const config: Config = {
    clinicId,
    asModal,
    embedded: true,
    successUrl,
    lang,
    sourceWebsite,
    mode: AppMode.NORMAL,
    privacyLink,
    generalTermsAndConditionsLink,
    colors: toJSON(colors),
    sourceWebsiteType: sourceWebsiteType ?? SourceWebsiteType.AVAILY_OTHER,
    ...getTrackingParams(window.location),
  }
  if (fallbackIframe && elementId) return createFallbackIframe(elementId, config)

  if (asModal) return createModalIframe(scriptUrl, config)

  return createEmbeddedIframe({ elementId, scriptUrl }, config)
}

const createModalIframe = (scriptUrl: string, config: Config) => {
  const iframe = document.createElement('iframe')
  iframe.id = IFRAME_ELEMENT_ID
  iframe.style.width = '100%'
  iframe.style.border = 'none'
  iframe.dataset.testid = 'availy-iframe'

  iframe.style.height = '100%'
  iframe.style.position = 'fixed'
  iframe.style.left = '0'
  iframe.style.top = '0'
  iframe.style.zIndex = '999999'
  iframe.style.display = 'none'

  const html = createHtml('root', scriptUrl, config)

  document.body.append(iframe)

  // without setTimeout Firefox doesn't append any children to iframe
  setTimeout(() => {
    iframe.contentDocument?.open()
    iframe.contentDocument?.appendChild(html)
    iframe.contentDocument?.close()
  })
}

const createEmbeddedIframe = ({ elementId, scriptUrl }: { elementId?: string; scriptUrl: string }, config: Config) => {
  const iframe = document.createElement('iframe')

  iframe.id = IFRAME_ELEMENT_ID
  iframe.style.width = '100%'
  iframe.style.border = 'none'
  iframe.dataset.testid = 'availy-iframe'

  const elementToAttach = elementId && document.getElementById(elementId)

  if (elementToAttach) {
    const html = createHtml('root', scriptUrl, config)
    elementToAttach.append(iframe)
    iframe.contentDocument?.open()
    iframe.contentDocument?.appendChild(html)
    iframe.contentDocument?.close()
  } else {
    throw new Error(`Availy could not be initialized. Element with id ${elementId} was not found in the page.`)
  }
}

const createFallbackIframe = (elementId: string, config: Config) => {
  const iframe = document.createElement('iframe')

  const queryParams = qs.stringify(config)

  iframe.src = `${availyUrl}?${queryParams}`
  iframe.id = 'availy-iframe'
  iframe.style.width = '100%'
  iframe.style.border = 'none'

  if (config.asModal) {
    iframe.style.height = '100%'
    iframe.style.position = 'fixed'
    iframe.style.left = '0'
    iframe.style.top = '0'
    iframe.style.border = 'none'
    iframe.style.zIndex = '999999'
    iframe.style.display = 'none'

    return document.body.append(iframe)
  }

  const elementToAttach = document.getElementById(elementId)

  if (elementToAttach) {
    elementToAttach.append(iframe)
  } else {
    throw new Error(`Availy could not be initialized. Element with id ${elementId} was not found in the page.`)
  }
}

const testIframeAbleToLoadScripts = (callback: (result: boolean) => void) => {
  const iframe = document.createElement('iframe')

  iframe.id = 'availy_test_iframe'
  iframe.style.display = 'none'

  const html = document.createElement('html')
  const body = document.createElement('body')
  const head = createHead()

  window.onmessage = (event: any) => {
    if (event.data === 'IFRAME_TEST_SUCCESS') {
      iframe.remove()
      return callback(true)
    }

    if (event.data === 'IFRAME_TEST_FAILED') {
      iframe.remove()
      return callback(false)
    }
  }

  const configScript = createConfigScript(() => {})

  const script = document.createElement('script')
  script.text = `
      var intervals = 0
      var interval = setInterval(function () {
        var intervalLimit = 5 // 500ms
        if (intervals > intervalLimit) {
          window.parent.postMessage('IFRAME_TEST_FAILED')
          return clearInterval(interval)
        }

        var ableToLoad = window?.env && (window?.env?.IS_DEV || window?.env?.REACT_APP_API_BASE_URL)

        if (ableToLoad) {
          window.parent.postMessage('IFRAME_TEST_SUCCESS')
          return clearInterval(interval)
        }

        intervals += 1
      }, 100)
    `
  body.appendChild(configScript)
  body.appendChild(script)

  html.appendChild(head)
  html.appendChild(body)

  document.body.append(iframe)

  iframe.contentDocument?.open()
  iframe.contentDocument?.appendChild(html)
  iframe.contentDocument?.close()
}

const preloadAvailyScript = ({ scriptUrl }: { scriptUrl: string }) => {
  const script = createScript(scriptUrl, () => {})
  document.body.appendChild(script)
}

const isAvailyLoaded = (): boolean => {
  return Boolean(document.getElementById(IFRAME_ELEMENT_ID))
}

const handleAvailyMessages = ({
  asModal = false,
  initialOverflowY = '',
  data,
  loadOnDemand = false,
}: HandleAvailyMessagesProps) => {
  const iframeElement = document.getElementById(IFRAME_ELEMENT_ID) as HTMLIFrameElement

  switch (data.type) {
    case 'INIT': {
      if (asModal) {
        if (loadOnDemand) showIframe()
      }

      break
    }

    case 'HEIGHT_CHANGED': {
      if (!asModal) {
        const height = data?.payload > 500 ? data.payload : 500

        setIframeHeight({ height })
      }

      break
    }

    case 'BOOKING_CREATED': {
      if (data.payload) {
        /**
         * When a booking is created the payload is a url string
         * The forwarding is delayed to avoid possible Analytics
         * event cancellation
         */
        setTimeout(() => {
          window.location.href = data.payload as string
        }, 1000)
      }

      break
    }

    case 'CLOSE_MODAL': {
      iframeElement.style.display = 'none'
      document.body.style.overflowY = initialOverflowY

      break
    }

    case 'OPEN_MODAL': {
      iframeElement.style.display = 'block'
      break
    }

    case 'GTM_EVENT_EMMITED': {
      if (data.payload) {
        if (data?.category === 'form') {
          dispatchAnalyticsEvent(data.payload.action, data.payload.label, 'form')
        } else {
          dispatchTrackingEvent(data.payload.screen, data.payload.action)
        }
      }

      break
    }

    case 'GTM_FORM_EVENT_EMMITED': {
      if (data.payload) {
        dispatchAnalyticsEvent(data.payload.action, data.payload.label, 'form')
      }

      break
    }

    // eslint-disable-next-line no-empty
    default: {
    }
  }
}

const showIframe = () => {
  const iframeElement = document.getElementById(IFRAME_ELEMENT_ID) as HTMLIFrameElement
  if (iframeElement) {
    iframeElement.style.display = 'block'
  }
  document.body.style.overflowY = 'hidden'

  postAvailyMsg({
    type: 'OPEN_MODAL',
  })
}

const bindToggleButtons = ({ loadOnDemand, loadAvaily }: { loadOnDemand: boolean; loadAvaily: () => void }) => {
  const availyToggleElements = document.querySelectorAll('[data-toggle-availy]')

  if (availyToggleElements.length > 0) {
    for (const availyToggleElement of availyToggleElements as any) {
      availyToggleElement.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault()
        /**
         * When the button is clicked, we only load Availy
         * and the iframe will be shown once Availy triggers
         * it's INIT event
         */
        if (loadOnDemand) return loadAvaily()

        showIframe()
      })
    }
  }
}

const addAvailyEventListener = ({ asModal, initialOverflowY, loadOnDemand }: Partial<HandleAvailyMessagesProps>) => {
  listenMessages((data) => handleAvailyMessages({ asModal, initialOverflowY, data, loadOnDemand }))
}

const initAvaily = ({
  asModal = false,
  elementId,
  clinicId,
  successUrl,
  lang = document.documentElement.lang,
  loadOnDemand = false,
  colors,
  sourceWebsiteType = SourceWebsiteType.AVAILY_OTHER,
  privacyLink,
  generalTermsAndConditionsLink,
}: InitAvaily) => {
  if (!clinicId) throw new Error('clinicId must be provided.')
  if (!asModal && !elementId) throw new Error('The elementId was not provided.')

  const sourceWebsite = `${window.location.origin}${window.location.pathname}`

  testIframeAbleToLoadScripts((ableToLoad) => {
    const scriptUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://127.0.0.1:3000/static/js/main.chunk.js'
        : `${availyUrl}/static/js/availy.min.js`

    /**
     * We are preloading the main script file as it is the heaviest file in Availy.
     * This way, when the same file is loaded inside the iframe,
     *  it will be then loaded from the browser cache
     */
    if (loadOnDemand) preloadAvailyScript({ scriptUrl })

    /**
     * When loadOnDemand is set to true
     * we wil load the iframe only when the user
     * clicks on the button to open Availy
     */
    if (!loadOnDemand) {
      createIframe({
        asModal,
        elementId,
        clinicId,
        successUrl,
        lang,
        sourceWebsite,
        scriptUrl,
        fallbackIframe: !ableToLoad,
        colors,
        sourceWebsiteType,
        privacyLink,
        generalTermsAndConditionsLink,
      })
    }

    window.requestAnimationFrame(() => {
      const initialOverflowY = document.body.style.overflowY

      addAvailyEventListener({ asModal, initialOverflowY, loadOnDemand })

      if (asModal) {
        bindToggleButtons({
          loadOnDemand,
          loadAvaily: () => {
            const availyLoaded = isAvailyLoaded()
            if (availyLoaded) return showIframe()

            createIframe({
              asModal,
              elementId,
              clinicId,
              successUrl,
              lang,
              sourceWebsite,
              scriptUrl,
              fallbackIframe: !ableToLoad,
              privacyLink,
              generalTermsAndConditionsLink,
            })
          },
        })
      }
    })
  })
}

window.initAvaily = initAvaily
